<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-media class="d-flex flex-column" no-body>
        <b-media-aside>
          <b-link>
            <b-img ref="previewEl" rounded :src="profileImage && profileImage !== null
                ? profileImage.includes(imagePath)
                  ? profileImage
                  : imagePath + profileImage
                : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
              " height="80" />
          </b-link>
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="handleClickProfileImage">
            Upload
            <div style="display: none">
              <b-form-file v-model="profileImage" id="fileUpload" accept="image/*"
                @input="handleFileChange($event, 'profileimage')" />
            </div>
          </b-button>
          <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="removeprofileImage">
            Reset
          </b-button>
        </b-media-body>
      </b-media>
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <label>Name</label>
                <label style="color: red !important">*</label>
                <b-form-input v-model="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Surname</label>
              <b-form-input v-model="surname" placeholder="Enter Name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Father / Husband Name">
                <label>Father / Husband Name</label>
                <label style="color: red !important">*</label>
                <b-form-input v-model="fathername" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|email" name="Email">
                <b-form-input v-model="email" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Mobile">
                <b-form-input v-model="number" :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Enter Number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Gender">
                <v-select v-model="gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  placeholder="None" :options="['Male', 'Female']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Birth Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Birth Date">
                <flat-pickr v-model="birthdate" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="ifEdit ? '' : 'display:none'">
            <b-form-group>
              <label>Username</label>
              <b-form-input v-model="username" disabled type="text" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Username" />
            </b-form-group>
          </b-col>
          <b-col md="3" :style="ifEdit ? 'display:none' : ''">
            <b-form-group>
              <label>Password</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" :rules="ifEdit ? '' : 'required|password'" name="Password">
                <b-input-group class="input-group-merge">
                  <b-form-input v-model="password" placeholder="Enter Password" :type="passwordFieldTypeNew" />

                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Joining Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Joining Date">
                <flat-pickr v-model="joiningdate" class="form-control" :config="{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                }" style="background: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="ifEdit ? '' : 'display:none'">
            <b-form-group>
              <label>Resign Date</label>
              <flat-pickr placeholder="Select Date" v-model="resigndate" class="form-control" :config="{
                enableTime: false,
                dateFormat: 'd/m/Y',
                minDate: joiningdate,
              }" style="background: transparent" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Marital Status</label>
              <v-select v-model="maritalstatus" placeholder="None" :options="['Married', 'Unmaried']" />
            </b-form-group>
          </b-col>
          <b-col md="3" :style="maritalstatus == 'Married' ? '' : 'display:none'">
            <b-form-group>
              <label>Anniversary Date</label>
              <flat-pickr v-model="anniversarydate" placeholder="Select Date" class="form-control" :config="{
                dateFormat: 'd/m/Y',
                maxDate: new Date(),
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Current Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 1">
                <b-form-input v-model="curaddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 2">
                <b-form-input v-model="curaddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|digits:6" name="Pin Code">
                <b-form-input v-model="curpincode" maxlength="6" @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select v-model="curcity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="City"
                  :options="curcityOption" @input="clickArea($event)" placeholder="None" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select v-model="curarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="PostOfficeName"
                  :options="curareaOption" placeholder="None" @input="handleCurPincode()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="curtaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select v-model="curstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                  placeholder="None" label="State" @input="handleCurCity()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Permanent Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
            <b-form-checkbox @input="handlePermanent" class="ml-2" inline>
              Same As Current Address
            </b-form-checkbox>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <b-form-input v-model="peraddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 1" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <b-form-input v-model="peraddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 2" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <validation-provider #default="{ errors }" rules="digits:6" name="pin Code">
                <b-form-input v-model="perpincode" maxlength="6" @input="clickPerPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <v-select v-model="percity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="percityOption"
                @input="clickPerArea($event)" label="City" placeholder="None" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <v-select v-model="perarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perareaOption"
                placeholder="None" @input="handlePincode()" label="PostOfficeName" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="pertaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <v-select v-model="perstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                placeholder="None" label="State" @input="handleCity()" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Reporting Authority</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Reporting Authority">
                <v-select v-model="reportingauthority" label="fullname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="None"
                  :options="reportingauthorityoption">
                  <template #option="{ fullname, profile_image, username, color, position }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-' + color">
                      {{ fullname }} / {{ position ? position.name : "" }} /
                      {{ username }}
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Commission Type</label>
              <v-select v-model="commissiontype" label="type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="None" :options="commissionOption" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Salary</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Salary">
                <b-form-input v-model="salary" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number"
                  placeholder="Enter Salary" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Designation</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Designation">
                <v-select v-model="designation" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None" :options="designationoption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Branch">
                <v-select v-model="branch" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None" :options="branchoption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Work Experience</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="IFSC Code">
                <b-form-input v-model="experience" placeholder="Enter Work Experience" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Previous Company Name</label>
              <b-form-input v-model="previous_company" placeholder="Enter Company Name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Previous Designation</label>
              <b-form-input v-model="previous_designation" placeholder="Enter Designation" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Previous Salary</label>
              <b-form-input v-model="previous_salary" placeholder="Enter Salary" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Education</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Education">
                <b-form-input v-model="education" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Education" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Pan No">
                <b-form-input v-model="panno" maxlength="10" @input="validatePanClick($event)"
                  placeholder="Enter PAN No" />
                <small v-if="pannoValidate == false" class="text-danger">Enter Valid PAN No</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar No">
                <b-form-input v-model="aadharcardno" maxlength="14" @input="validateAadharClick($event)"
                  placeholder="Enter Aadhar No" />
                <small v-if="aadharcardnoValidate == false" class="text-danger">Enter Valid Aadhar No</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Bank">
                <b-form-input v-model="bankname" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Bank" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Branch">
                <b-form-input v-model="bankbranch" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Branch" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account No</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Account No">
                <b-form-input v-model="bankaccno" type="number" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Account No" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="IFSC Code">
                <b-form-input v-model="ifsccode" maxlength="11" @input="validateIFSCCodeClick($event)"
                  placeholder="Enter IFSC Code" />
                <small v-if="ifsccodeValidate == false" class="text-danger">Enter Valid IFSC Code</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar Card Front Side">
                <label>Aadhar Card Front Side</label>
                <label style="color: red !important">*</label>
                <b-form-file v-model="documents.aadharfrontside" placeholder="Select Photo"
                  drop-placeholder="Drop file here..." accept="image/*"
                  @input="handleFile($event, 'aadharfrontside', 'employee')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.aadharfrontside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar Card Back Side">
                <label>Aadhar Card Back Side</label>
                <label style="color: red !important">*</label>
                <b-form-file v-model="documents.aadharbackside" placeholder="Select Photo"
                  drop-placeholder="Drop file here..." accept="image/*"
                  @input="handleFile($event, 'aadharbackside', 'employee')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.aadharbackside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Pan Card ">
                <label>Pan Card</label>
                <label style="color: red !important">*</label>
                <b-form-file v-model="documents.pancard" placeholder="Select Photo" drop-placeholder="Drop file here..."
                  accept="image/*" @input="handleFile($event, 'pancard', 'employee')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.pancard" />
          </b-col>

          <b-col md="4" :style="designation && designation.name == 'Lead Manager' ? '' : 'display:none'
            ">
            <b-form-group>
              <label>Lead Access Of</label>
              <v-select v-model="leadaccess" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="None"
                :options="['Channelpartner', 'Employee', 'Both']" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Documents" no-body />
          </b-col>

          <b-col md="12">
            <div style="width: 100%; max-height: 500px; overflow: auto">
              <table class="table table-bordered" style="min-width: 15cm">
                <thead class="text">
                  <tr>
                    <th>Sr. No</th>
                    <th>Document Name</th>
                    <th>Attachment</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in details" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.name" placeholder="Enter Deatils" />
                    </td>
                    <td>
                      <b-form-file v-model="item.attachment" placeholder="Select Document"
                        drop-placeholder="Drop file here..." @input="
                          handleMultiFileChange($event, 'documents', 'employee', id)
                          " />
                      <attachment :data="item.attachment" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col cols="12" class="mt-1">
            <b-button variant="primary" type="submit" @click.prevent="submitForm($event)" class="mr-4" :disabled="flag">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../components/Attechment.vue";

import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    Attachment,
    BFormCheckbox,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,

    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: "",
      email: "",
      number: "",
      gender: "",
      username: "",
      password: "",
      joiningdate: "",
      resigndate: null,
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      profileImageFile: null,
      profileImage: null,
      curaddressline1: "",
      curaddressline2: "",
      curarea: "",
      curtaluka: "",
      curstate: "",
      curcity: "",
      curpincode: "",
      peraddressline1: "",
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      peraddressline2: "",
      perarea: "",
      pertaluka: "",
      perstate: "",
      percity: "",
      perpincode: "",
      curcityOption: [],
      curareaOption: [],
      percityOption: [],
      perareaOption: [],

      reportingauthority: "",
      reportingauthorityoption: [],
      designation: "",
      branch: "",
      designationoption: [],
      branchoption: [],
      ifEdit: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      commissiontype: "",
      salary: "",
      commissionOption: [],
      fromCurState: "",
      fromState: "",
      stateOptions: [],
      passwordFieldTypeNew: "password",

      aadharcardno: "",
      bankaccno: "",
      bankbranch: "",
      bankname: "",
      ifsccode: "",
      panno: "",
      maritalstatus: "",
      anniversarydate: "",
      birthdate: "",
      documents: {
        aadharfrontsideFile: null,
        aadharfrontside: "",
        aadharbacksideFile: null,
        aadharbackside: "",
        pancardFile: null,
        pancard: "",
      },

      aadharcardnoValidate: true,
      ifsccodeValidate: true,
      pannoValidate: true,
      flag: false,
      fathername: "",
      surname: "",
      experience: "",
      education: "",
      testPanno: "",
      testAadharno: "",
      details: [
        {
          name: "",
          attachment: [],
        },
      ],
      previous_company: "",
      previous_designation: "",
      previous_salary: "",
      leadaccess: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Employee") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/hr/employee");
            }
          } else if (item.add !== 1) {
            this.$router.push("/hr/employee");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    addRow() {
      this.details.push({
        details: "",
        attachment: [],
      });
    },
    removeRow(index) {
      this.details.splice(index, 1);
    },
    async handleMultiFileChange(e, name, type, index) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.details[index].attachment =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;

          this.flag = false;
        })
        .catch((error) => {
          this.flag = false;
        });
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.aadharcardno.length == 4
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      this.aadharcardno.length == 9
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      if (regex.test(this.aadharcardno) == true) {
        this.aadharcardnoValidate = true;
      } else {
        this.aadharcardnoValidate = false;
      }
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    async handleFile(e, name, type) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          this.documents[name] =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => {
          console.log(error, "error");
          this.flag = false;
        });
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    handlePermanent(e) {
      if (e == true) {
        this.perarea = this.curarea;
        this.perstate = this.curstate;
        this.percity = this.curcity;
        this.pertaluka = this.curtaluka;
        this.perpincode = this.curpincode;
        this.peraddressline1 = this.curaddressline1;
        this.peraddressline2 = this.curaddressline2;
      } else {
        this.perarea = "";
        this.perstate = "";
        this.percity = "";
        this.pertaluka = "";
        this.perpincode = "";
        this.peraddressline1 = "";
        this.peraddressline2 = "";
      }
    },
    removeprofileImage() {
      this.profileImage = null;
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    handleClickProfileImage() {
      document.getElementById("fileUpload").click();
    },
    getEditItems(item) {
      item.map((item) => {
        this.name = item.name;
        this.experience = item.experience;
        this.fathername = item.fathername;
        this.surname = item.surname;
        this.education = item.education;
        this.test = item.email;
        this.email = item.email;
        this.number = item.mobile;
        this.gender = item.gender;
        this.username = item.username;
        this.password = item.password;
        this.joiningdate =
          item.joiningdate == "00/00/0000" || item.joiningdate == null
            ? null
            : item.joiningdate;
        this.birthdate =
          item.birthdate == "00/00/0000" || item.birthdate == null
            ? null
            : item.birthdate;
        this.resigndate =
          item.resigndate == "00/00/0000" || item.resigndate == null
            ? null
            : item.resigndate;
        this.anniversarydate =
          item.anniversarydate == "00/00/0000" || item.anniversarydate == null
            ? null
            : item.anniversarydate;

        this.reportingauthority = item.parent;
        this.designation = item.position;
        this.branch = item.branchname;
        this.commissiontype = item.commissiontype;
        this.curaddressline1 = item.curaddressline1;
        this.salary = item.salary;
        this.curaddressline2 = item.curaddressline2;
        this.curarea = item.curarea;
        this.curtaluka = item.curtaluka;
        this.curcity = item.curcity;
        this.curpincode = item.curpincode;
        this.curstate = item.curstate;
        this.peraddressline1 = item.peraddressline1;
        this.peraddressline2 = item.peraddressline2;
        this.perarea = item.perarea;
        this.pertaluka = item.pertaluka;
        this.percity = item.percity;
        this.perpincode = item.perpincode;
        this.perstate = item.perstate;
        this.profileImage = item.profile_image;
        this.aadharcardno = item.aadharcardno;
        this.testAadharno = item.aadharcardno;
        this.bankaccno = item.bankaccno;
        this.bankbranch = item.bankbranch;
        this.bankname = item.bankname;
        this.ifsccode = item.ifsccode;
        this.panno = item.panno;
        this.testPanno = item.panno;
        this.maritalstatus = item.maritalstatus;
        this.documents.aadharfrontside = item.aadharfrontside;
        this.documents.aadharbackside = item.aadharbackside;
        this.documents.pancard = item.pancard;
        this.details = item.details ? JSON.parse(item.details) : [];
        this.previous_company = item.previous_company;
        this.previous_designation = item.previous_designation;
        this.previous_salary = item.previous_salary;
        this.leadaccess = item.leadaccess;
      });
      this.location();
      // this.clickPincode()
      // this.clickPerPincode()
    },
    location() {
      this.handleCity();
      this.handleCurCity();
      this.handleCurArea();
      this.handleArea();
    },

    async handleFileChange(e, type) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.profileImage = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    init() {
      this.getBranch();
      this.getDesignation();
      this.getUser();
      this.getcommission();
      this.getState();
    },
    handlePincode() {
      if (this.fromState == true) {
        this.perpincode = this.perarea.Pincode;
      }
    },
    handleCurPincode() {
      if (this.fromCurState == true) {
        this.curpincode = this.curarea.Pincode;
      }
    },
    async handleCurCity() {
      this.curareaOption = [];
      this.curcityOption = [];
      this.fromCurState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.curstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curcityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.perareaOption = [];
      this.percityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.perstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.percityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.percity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.perareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickPerArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      // this.perareaOption = []
      // this.perStateData.map((item) => {
      //   if (item.City == e) {
      //     this.perareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPerPincode() {
      this.percity = "";
      this.perarea = "";
      this.perstate = "";
      this.perareaOption = [];
      this.percityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.perpincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.perpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.percityOption = [];
            this.perareaOption = response.data.data;
            this.perStateData = response.data.data;
            this.perstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.percityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleCurArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.curcity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromCurState == true) {
        this.handleCurArea();
      }
      // this.curareaOption = []
      // this.curStateData.map((item) => {
      //   if (item.City == e) {
      //     this.curareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPincode() {
      this.curcity = "";
      this.curarea = "";
      this.curstate = "";
      this.curareaOption = [];
      this.curcityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.curpincode)) {
        this.fromCurState = false;

        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.curpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.curcityOption = [];
            this.curStateData = response.data.data;
            this.curareaOption = response.data.data;
            this.curstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.curcityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async getBranch(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/branch`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.branchoption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getDesignation(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getPosition`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.designationoption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAdmin() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getalladmins`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.source = json.data[0];
          this.source.position = {
            name: "Admin",
          };
          this.reportingauthorityoption.unshift(this.source);
        })
        .catch((error) => console.log(error, "error"));
    },
    async getUser(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.reportingauthorityoption = response.data.data;
          this.getAdmin();
        })
        .catch((error) => console.log(error, "error"));
    },
    async getcommission(id) {
      const commission = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getcommission?for=Employee`,
      };
      await axios(commission)
        .then((response) => {
          this.commissionOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/hr/employee");
    },
    submitForm(e) {
      const data = {
        name: this.name,
        experience: this.experience,
        fathername: this.fathername,
        surname: this.surname,
        education: this.education,
        email: this.email,
        mobile: this.number,
        gender: this.gender,
        username: this.username,
        password: this.password,
        joiningdate: this.joiningdate,
        resigndate: this.resigndate,
        parent: this.reportingauthority ? this.reportingauthority.id : "",
        position: this.designation.id,
        branchname: this.branch.id,
        commissiontype: this.commissiontype ? this.commissiontype.id : "",
        salary: this.salary,
        curaddressline1: this.curaddressline1,
        curaddressline2: this.curaddressline2,
        curarea: this.curarea.PostOfficeName,
        curtaluka: this.curtaluka,
        curcity: this.curcity.City,
        curpincode: this.curpincode,
        curstate: this.curstate.State,
        peraddressline1: this.peraddressline1,
        peraddressline2: this.peraddressline2,
        perarea: this.perarea ? this.perarea.PostOfficeName : "",
        pertaluka: this.pertaluka,
        percity: this.percity ? this.percity.City : "",
        perpincode: this.perpincode,
        perstate: this.perstate ? this.perstate.State : "",
        profile_image: this.profileImage,
        aadharcardno: this.aadharcardno,
        bankaccno: this.bankaccno,
        bankbranch: this.bankbranch,
        bankname: this.bankname,
        ifsccode: this.ifsccode,
        panno: this.panno,
        birthdate: this.birthdate,
        aadharfrontside: this.documents.aadharfrontside,
        aadharbackside: this.documents.aadharbackside,
        pancard: this.documents.pancard,
        maritalstatus: this.maritalstatus,
        anniversarydate: this.anniversarydate,
        details: JSON.stringify(this.details),
        previous_company: this.previous_company,
        previous_designation: this.previous_designation,
        previous_salary: this.previous_salary,
        leadaccess: this.leadaccess,
      };
      if (this.test == data.email) {
        delete data.email;
      }
      if (this.testPanno == data.panno) {
        delete data.panno;
      }
      if (this.testAadharno == data.aadharcardno) {
        delete data.aadharcardno;
      }
      if (this.ifEdit == data.password) {
        delete data.password;
      }
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.pannoValidate == false ||
          this.aadharcardnoValidate == false ||
          this.ifsccodeValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all the fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (
          success &&
          this.pannoValidate == true &&
          this.aadharcardnoValidate == true &&
          this.ifsccodeValidate == true
        ) {
          this.flag = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/team/${this.$route.params.id}`
              : `${baseApi}/team`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.flag = false;

              this.$router.push("/hr/employee");
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                    ? `${response.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.flag = false;
              let email, name, aadharcard, pancard, message;
              const code = error.toString().includes("409");
              if (code) {
                email = error.response.data.message.original.email
                  ? error.response.data.message.original.email[0]
                  : "";
                name = error.response.data.message.original.name
                  ? error.response.data.message.original.name[0]
                  : "";
                aadharcard = error.response.data.message.original.aadharcardno
                  ? error.response.data.message.original.aadharcardno[0]
                  : "";
                pancard = error.response.data.message.original.panno
                  ? error.response.data.message.original.panno[0]
                  : "";
                message = `${email} ${aadharcard} ${pancard} ${name}`;
              }

              this.$swal({
                title: "Error!",
                text: code ? message : error?.response?.data?.message,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
